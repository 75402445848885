<template>
  <div class="h-screen">

    <div class="absolute left-5 pt-2.5">
      <div class="w-full mx-auto sm:max-w-md mb-7">
        <Brand size="normal" />
      </div>
    </div>

    <div class="absolute right-5 pt-2.5">
      <div class="flex flex-center">
        <SelectLangs />

        <ul class="flex items-center HeaderMainNav">
          <li class="relative" v-click-away="closeProfileMenu">
            <button @click="toggleProfileMenu" class="items-center min-h-full group align-middle mx-2 px-2 cursor-pointer text-sm text-gray-400 focus:outline-none hover:text-gray-100 transition" :class="[$store.state.Menu.isProfileMenuOpen ? 'open' : '']" aria-label="Account" aria-haspopup="true">
              <div class="flex items-center">
                <div class="avatar placeholder">
                  <div class="bg-gray-300 text-gray-700 rounded-full w-8 h-8 inline md:mr-2.5" v-if="$store.state.Auth.user">
                    <span><img :src="$store.state.Auth.user.avatar" class="rounded-full w-10" /></span>
                  </div>
                </div>
                <div class="flex flex-col items-start">
                  <p class="text-sm text-gray-700 group-hover:text-gray-600 hidden md:inline" v-if="$store.state.Auth.user">{{ $store.state.Auth.user.name }}</p>
                </div>
                <Icon icon="tabler:chevron-down" class="-mr-1 ml-2 h-5 w-5 text-sm hidden md:inline text-gray-400" />
              </div>
            </button>
            <TransitionFade2>
              <ul v-if="$store.state.Menu.isProfileMenuOpen" class="transition-all z-10 ease-in-out absolute right-2.5 w-56 px-2 py-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700" aria-label="submenu">
                <li class="flex">
                  <button @click="logOut" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-red-100 hover:bg-opacity-80 logout">
                    <Icon icon="tabler:power" class="w-4 h-4 mr-3" />
                    <span>{{ $t("navbar.logOut") }}</span>
                  </button>
                </li>
              </ul>
            </TransitionFade2>
          </li>
        </ul>
      </div>
    </div>

    <!-- Project actions -->
    <div v-if="!form.successful" class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-20">
        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <div class="w-full mx-auto sm:rounded-lg md:max-w-lg mb-4 text-center">
            <h1 class="font-semibold text-lg lg:text-xl">{{ $t("register.projectForm.title") }} 🚀</h1>
            <p class="font-md text-gray-400">{{ $t("register.projectForm.subtitle") }}</p>
          </div>
          <Card>
            <template #title>
              <div class="mx-6 pt-4">
                <!-- Form title -->
                <h2 class="text-xl font-semibold">{{ $t("register.projectForm.completeYourProjectData") }}</h2>
              </div>
            </template>
            <template #content>
              <BlockUI :blocked="loadingFormValues" :baseZIndex="100" :autoZIndex="false">
                <BlockUILoader v-if="loadingFormValues" :text="$t('loadingData')" />

                <div class="flex flex-col w-full">
                  <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
                    <!-- Company field -->
                    <div class="form-group">
                      <label for="company" class="form-label">{{ $t("register.projectForm.company") }} <LabelRequired /></label>
                      <InputText v-model="form.company" id="company" name="company" type="text" class="p-inputtext-sm" />
                      <HasError :form="form" field="company" />
                    </div>

                    <!-- Industry field -->
                    <div class="form-group">
                      <label for="industry" class="form-label">{{ $t("register.projectForm.industry") }} <LabelRequired /></label>
                      <Dropdown v-model="form.industry_id" :options="industryOptions" optionLabel="name" dataKey="id" optionValue="id" :filter="true" :autoFilterFocus="true" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="industry_id" />
                    </div>

                    <!-- Country field -->
                    <div class="form-group">
                      <label for="country" class="form-label">{{ $t("register.projectForm.country") }} <LabelRequired /></label>
                      <Dropdown v-model="form.country_id" :options="countryOptions" optionLabel="name" dataKey="id" optionValue="id" :filter="true" :autoFilterFocus="true" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="country_id" />
                    </div>

                    <!-- Timezone field -->
                    <div class="form-group">
                      <label for="timezone" class="form-label">{{ $t("register.projectForm.timezone") }} <LabelRequired /></label>
                      <Dropdown v-model="form.timezone" :options="timezoneOptions" optionLabel="value" dataKey="value" optionValue="value" :filter="true" :autoFilterFocus="true" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="timezone" />
                    </div>

                    <!-- Currency field -->
                    <div class="form-group">
                      <label for="currency" class="form-label">{{ $t("register.projectForm.currency") }} <LabelRequired /></label>
                      <Dropdown v-model="form.currency_id" :options="currencyOptions" optionLabel="name" dataKey="id" optionValue="id" :filter="true" :autoFilterFocus="true" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="currency_id" />
                    </div>

                    <!-- Form submit button -->
                    <Button type="submit" :label="$t('register.projectForm.createProject')" :loading="form.busy" iconPos="right" class="p-button-primary p-button-lg w-full" />

                    <!-- Form error general -->
                    <HasError :form="form" field="error" />

                    <div class="mt-2">
                      <Message severity="info" class="ring-1 ring-blue-200" :closable="false">
                        ProTIP: {{ $t("register.projectForm.separateProjectsDisclaimer") }}
                      </Message>
                    </div>
                  </form>
                </div>
              </BlockUI>
            </template>
          </Card>

          <!-- Don't have an account!, please go to register -->
          <div v-if="user.config?.project_default_id" class="flex-col-center mt-3">
            <router-link :to="{ name: 'Onboarding redirect' }">
              <Button :label="$t('goToMyProjects')" class="p-button-secondary p-button-text" />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Project success -->
    <div v-else class="flex-col-center justify-center h-full">
      <Loader :onlyLoader="false" title="" :text="$t('savedDataRedirecting')" />
    </div>
  </div>
</template>

<script>
// Framework components
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"
import Message from "primevue/message"

import { Icon } from "@iconify/vue"
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import BlockUI from "primevue/blockui"

// App components
import TransitionFade2 from "@/components/TransitionFade2"
import Brand from "@/components/Brand"
import SelectLangs from "@/components/SelectLangs"
import LabelRequired from "@/components/LabelRequired"
import BlockUILoader from "@/components/UI/BlockUILoader"
import Loader from "@/components/UI/Loader"

export default {
  name: "Project",

  components: {
    // Framework components
    Icon,
    Card,
    Button,
    InputText,
    Dropdown,
    HasError,
    BlockUI,
    Message,
    // App components
    Brand,
    SelectLangs,
    LabelRequired,
    BlockUILoader,
    Loader,
    TransitionFade2
  },

  data() {
    return {
      token: this.$route.query?.accessToken || localStorage.getItem("Token"),
      user: {},
      industryOptions: [],
      countryOptions: [],
      timezoneOptions: [],
      currencyOptions: [],
      form: new FormClient({
        company: "",
        timezone: null,
        currency_id: null,
        country_id: null,
        industry_id: null,
        dataJson: {},
        is_active: 1
      }),
      loadingFormValues: false,
      formIndustryValues: new FormClient({}),
      formCountryValues: new FormClient({}),
      formTimezoneValues: new FormClient({}),
      formCurrencyValues: new FormClient({}),
      formUserValues: new FormClient({})
    }
  },

  async created() {
    if (!this.token || this.token == "null") {
      this.$router.push({ name: "Login" })
    }

    this.loadingFormValues = true
    await this.getFormValues()
    await this.login(this.token)
    this.loadingFormValues = false
  },

  methods: {
    async getFormValues() {
      const [industries, countries, timezone, currency] = await Promise.all([this.formIndustryValues.get(ApiRoute.utilities.industries), this.formCountryValues.get(ApiRoute.utilities.countries), this.formTimezoneValues.get(ApiRoute.utilities.timezone), this.formCurrencyValues.get(ApiRoute.utilities.currencies)])

      this.industryOptions = industries.data.data
      this.countryOptions = countries.data.data
      this.timezoneOptions = timezone.data.map((f) => {return {value: f}});
      this.currencyOptions = currency.data.data.map((item) => {
        item.name = `${item.name} (${item.symbol})`
        return item
      })
    },

    async login(token) {
      await this.$store.dispatch("Auth/setToken", token)
      const response = await this.formUserValues.get(ApiRoute.auth.profile)
      await this.$store.dispatch("Auth/setUser", response.data)
      this.user = response.data.data
    },

    async submit() {
      const response = await this.form.post(ApiRoute.project.store)
      this.$router.push({ name: "Form Integration", params: { id: response.data.data.id } })
    },


    toggleProfileMenu() {
      if (this.$store.state.Menu.isProfileMenuOpen) {
        this.closeProfileMenu()
      } else {
        this.openProfileMenu()
      }
    },

    openProfileMenu() {
      this.$store.dispatch("Menu/openProfileMenu")
    },

    closeProfileMenu() {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },

    logOut() {
      this.$store.dispatch("Auth/requestLogout")
    }
  }
}
</script>
